import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { SnackBar, Button } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "snackbar"
    }}>{`SnackBar`}</h1>
    <blockquote>
      <p parentName="blockquote">{`개발 중`}</p>
    </blockquote>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={SnackBar} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<SnackBar\n  message={\'Snack Bar!\'}\n  actionElement={<Button onClick={() => console.log(\'Clicked\')} />}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SnackBar,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SnackBar message={'Snack Bar!'} actionElement={<Button onClick={() => console.log('Clicked')} mdxType="Button" />} mdxType="SnackBar" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      